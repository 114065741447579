<div class="signin-container">

    <div class="signin-section">
        <div class="signin-inner">
            <div class="success_img"><img src="/assets/images/check.png" class="img-fluid" alt=""></div>
            <div class="signin-heading">
                <h1 class="title">Successfull</h1>
                <p class="title-desc">You have been registred successfully.</p>
                <p class="title-desc">A verification code has been sent to your email address.</p>
            </div>
            <div class="btn_wrapper mt-3">
                <a routerLink="/" class="btn login">Go to Home</a>
            </div>
        </div>
    </div>
</div>